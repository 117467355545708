#flow {
    padding: 7.5% 0;
    background: #141414;
}

#product #flow {
    background: #fff;
}

#flow .description {
    text-align: center;
}

#flow .description h1 {
    color: #fff;
}

#product #flow .description h1 {
    color: #141414;
}

#flow .description p {
    color: #fff;
}

#product #flow .description p {
    color: #141414;
}

#flow svg {
    font-size: 5rem;
    color: #ffffff;
}

#flow svg.arrow-flow,
#flow svg.arrow-mobile {
    opacity: 0.8;
}

#product #flow svg.arrow-flow,
#product #flow svg.arrow-mobile {
    color: #141414;
}

#flow .row:nth-child(2) {
    justify-content: space-around;
    margin-top: 2.5rem;
    padding: 0 10%;
}

.flow_wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-basis: 30%;
    justify-content: center;
    align-items: center;
}

#flow .flow_content {
    text-align: center;
}

#flow .flow_content span {
    margin: 0 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#flow .flow_content span h2 {
    font-size: 1.25rem;
    color: #fff;
    line-height: 1.5;
}

#product #flow .flow_content span h2 {
    color: #141414;
}

#flow .flow_content span p {
    font-size: 1.05rem;
}

#flow .flow_wrap .my-icon .fa-primary {
    fill: #fff;
}

#product #flow .flow_wrap .my-icon .fa-primary {
    fill: #141414;
}

/* Mobile Displaying */

@media (max-width:1024px){

    #flow {
        padding: 20% 0;
    }

    #flow .description {
        padding: 0 10% 7.5% 10% !important;
    }

    #flow .row:nth-child(2) {
        margin: 0;
    }

    #flow .flow_wrap {
        flex-direction: column;
        flex-basis: 100%;
    }
    
    #flow svg {
        font-size: 3.5rem;
    }

    #flow svg.arrow-flow {
        display: none;
    }

    #flow svg.arrow-mobile {
        margin: 2rem 0 1rem 0;
    }

    #flow .flow_content span h2 {
        font-size: 1.15rem;
    }

    #flow .flow_content span {
        margin: 0;
    }

    #flow img.logo-only {
        width: 80px;
        height: 80px;
    }
}

@media (min-width: 1025px) {

    #flow svg.arrow-flow {
        justify-self: right;
        float: right;
        position: absolute;
        right: -10.5%;
        top: 40%;
    }

    #flow svg.arrow-mobile {
        display: none;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {

    #flow {
        padding: 10% 0;
    }

    #flow .row:nth-child(2) {
        padding: 0 7.5%;
    }

    #flow .description h1 {
        font-size: 2rem;
    }

    #flow .flow_content span {
        margin: 0;
    }

    #flow .flow_content span h2 {
        font-size: 1.15rem;
    }

    #flow svg {
        font-size: 4.25rem;
    }

    #flow img.logo-only {
        width: 85px;
        height: 85px;
    }
}