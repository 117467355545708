nav {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    padding: 1% 0 0 0;
}

nav .nav_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
}

nav ul.navbar {
    display: flex;
    align-items: center;
    padding: 0;
    list-style-type: none;
}

@media (max-width:1024px){
    nav ul.navbar{
        display: none;
    }
}

nav ul.navbar li {
    padding: 0 2.5rem;
}

nav ul.navbar li a {
    display: inline-block;
    position: relative;
    color: #fff; 
    text-decoration: none;
    padding-bottom: 8px;
}

nav ul.navbar li a:after {
    transition: 0.35s all;
    width: 0;
    left: calc(100% - 3px);
    content: '';
    height: 2px;
    background: #4c6292;
    position: absolute;
    bottom: 0;
}

nav ul.navbar li a:hover:after {
    transition: 0.35s width;
    width: calc(100% - 3px);
    left: 0;
}

/* Multilanguage Buttons */

.multi-language button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.multi-language button:focus {
    border: none;
    outline: none;
}

.multi-language img {
    width: 30px;
}

/* Mobile Displaying */

@media (max-width: 1024px) {
    nav .nav_container {
        padding: 2% 5%;
    }
    .logo img {
        width: 150px;
    }
}