.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Main Style Changes */

main {
  background: #f2f2f2;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.logo img {
  width: 175px;
  height: 100%;
}

nav a {
  font-size: 1.15rem;
}

h1 {
  font-size: 2.5rem;
  margin: 0;
}

p {
  font-size: 1.25rem;
  line-height: 2;
  color: rgba(0, 0, 0, 0.65) ;
}

.my-icon .fa-primary {
  fill: #141414;
  opacity: 0.8;
}
.my-icon .fa-secondary {
  fill: orange;
  opacity: 0.8;
}