#card {
    display: flex;
    background: red;
    /* width: 80%; */
    margin: 1.5rem 5rem;
    /* border-radius: 5px; */
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
}

#card .card_image {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    max-height: 500px;
    overflow: hidden;
}

#card .card_image img {
    object-fit: cover;
    object-position: center;
    width: 100%;
}

#card .card_content {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
}

/* .card {
    flex-basis: 40%;
    background: #fff;
    margin-right: 5%;
}

.card .card_image img {
    width: 100%;
}

.card .card_content {
    padding: 5%;
} */