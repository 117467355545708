#blog {
    display: flex;
    align-items: center;
    padding: 10rem 0;
}

#blog .container .row {
    justify-content: center;
    padding: 0 10%;
}

