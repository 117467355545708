nav.drawer {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    background: #141414;
    transform: translateX(100%);
    transition: transform 0.75s ease-out;
}

nav.drawer.open {
    transform: translateX(0);
}

nav.drawer ul {
    width: 100%;
    padding: 35% 10%;
    list-style: none;
    display: flex;
    flex-direction: column;
}

nav.drawer ul li {
    padding: .75rem 0;
}

nav.drawer ul li a {
    display: inline-block;
    position: relative;
    font-size: 1.5rem;
    color: #fff;
    text-decoration: none;
}

nav.drawer ul li a:after {
    transition: 0.35s all;
    width: 0;
    left: calc(100% - 3px);
    content: '';
    height: 2px;
    background: #4c6292;
    position: absolute;
    bottom: -5px;
}

nav.drawer ul li a:hover:after {
    transition: 0.35s width;
    width: calc(100% - 3px);
    left: 0;
}