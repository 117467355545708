#features {
    display: flex;
    align-items: center;
    padding: 10rem 0;
}

#features .container .row {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 10%;
}

#features .container .row .features_content {
    flex-basis: 20%;
    margin-top: 2.5rem;
}

#home #features .container .row .features_content {
    flex-basis: 33%;
}

#features .container .row h1 {
    width: 100%;
    font-size: 2.25rem;
    line-height: 1.5;
    padding-right: 50%;
}

#features .container .row p {
    padding-right: 65%;
    font-size: 1.4rem;
    margin-top: 2.5rem;
}

#features svg {
    font-size: 4.5rem;
    color: #4c6292;
}

#features h2 {
    font-size: 1.25rem;
    margin-bottom: 0;
    padding-right: 15%;
}

/* Mobile Displaying */

@media (max-width:1024px) {

    #features {
        padding: 20% 10%;
        text-align: center;
    }

    #features .container .row {
        justify-content: center;
        padding: 0;
    }

    #features .container .row h1 {
        font-size: 1.85rem;
        padding: 0;
    }

    #features h2 {
        font-size: 1.15rem;
        line-height: 1.5;
        padding: 0;
    }

    #features .container .row p {
        font-size: 1.15rem;
        padding: 0;
    }

    #features .container .row .features_content,
    #home #features .container .row .features_content {
        flex: 1;
        flex-basis: 100%;
        padding: 0;
    }

    #features svg {
        font-size: 3.5rem;
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {

    #features .container .row h1 {
        font-size: 2rem;
    }

    #features h2 {
        font-size: 1.15rem;
    }

    #features svg {
        font-size: 4rem;
    }
}